import React from 'react';
import { styled, StyledComponent, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import AppShellSitePagesType from './../../AppShell/Models/SitePages.type';
import AppShellMarketType from './../../AppShell/Models/Market.type';
import AppShellContentType from './../../AppShell/Models/Content.type';
import Button, { Appearance as ButtonAppearance, PropType as ButtonPropType } from 'Shared/Button';
import CartIndicatorButton from './CartIndicatorButton';
import { isCompact } from 'Shared/Viewport';
import SiteHeaderType from './SiteHeader.type';
import * as style from 'Shared/Style';
import { Menu24x24 } from 'Shared/Icons/Menu';
import { AccountBoxScene, openAccountBox } from 'SiteLayout/AccountBox/action-creators';
import { ActionIconFromIconCode, iconCodes, MenuLinkItemOnClick } from 'SiteLayout/MainMenu';
import MenuLinkItem from 'SiteLayout/MainMenu/MenuLinkItem.type';
import GetFlagIcon from 'Shared/Icons/Flags'

type ConnectPropType = {
  pages: AppShellSitePagesType;
  header: SiteHeaderType;
  currentBreakpoint: number;
  quickSearchIsOpen: boolean;
  twoLetterCountryCode: string;
  language: string;
  market: AppShellMarketType;
  content: AppShellContentType;
  storeMode: boolean;
};

type PropType = ConnectPropType & {
  withText: boolean;
  inverseFGColor: boolean;
};

type ActionButtonPropType = ButtonPropType & {
  text?: string;
  Icon?: StyledComponent<any>;
  withText?: boolean;
  alwaysText?: boolean;
};

const Base = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
  ['@media ' + style.mediaMinLarge]: {
    gap: '24px'
  },
});

const IconText = styled.span({
  fontWeight: 450,
  marginLeft: '5px',
  verticalAlign: 'text-top',
  display: 'none',
  fontSize: '16px',
  ['@media ' + style.mediaMinLarge]: {
    display: 'inline-block',
  },
});

const AlwaysIconText = styled(IconText, {
  display: 'inline-block',
  fontSize: '14px',
  marginTop: '4px',
  marginLeft: 0,
  fontWeight: 'bold',
});

const ButtonWrap = styled.div({
  display: 'flex',
  fontSize: '14px',
})

const ButtonInner = styled(Button, {
  display: 'inline-flex',
})

const ButtonInnerWithText = styled(ButtonInner, {
  flexDirection: 'column',
  alignItems: 'center'
})

const CartIndicator = (props: { linkItem: MenuLinkItem, type: string, inverseFGColor: boolean, onClick: (e: any) => void; }) => {
  return (
    <CartIndicatorButton
      url={props.linkItem.url}
      title={props.linkItem.title}
      cartType={props.type}
      withText={false}
      onClick={props.onClick}
      inverseFGColor={props.inverseFGColor}
    />
  );
};

export const ActionButton = styled(function ActionButton(props: ActionButtonPropType & StyledProps) {
  const { compose, text, Icon, withText, ...buttonProps } = props;
  const showText = !!props.text && props.withText;
  const alwaysText = !!props.text && props.alwaysText;
  const BtnWrap = alwaysText ? ButtonInnerWithText : ButtonInner;
  return (
    <ButtonWrap>
      <BtnWrap {...buttonProps} appearance={ButtonAppearance.Bare}>
        <Icon />
        {alwaysText && <AlwaysIconText>{text}</AlwaysIconText>}
        {!alwaysText && showText && <IconText>{text}</IconText>}
      </BtnWrap>
    </ButtonWrap>
  );
});

const ActionButtons = styled(
  (
    props: PropType &
      StyledProps & {
        toggleTopLinks: () => void;
        openAccountBox: () => void;
      },
  ) => {
    const {
      header,
      toggleTopLinks,
      openAccountBox,
      inverseFGColor,
    } = props;

    const isMobile = isCompact(props.currentBreakpoint);
    const cartLinkItem = header.functionLinks && header.functionLinks.find(x => x.iconCode === 'CART');
    const wishListItem = header.functionLinks && header.functionLinks.find(x => x.iconCode === 'WISHLIST');
    const filteredFunctionLinks = header.functionLinks && header.functionLinks.filter(f => !['CART', 'WISHLIST'].includes(f.iconCode) && (isMobile ? f.relocateForSmallDevice === false : f.iconCode !== 'NOVALUE'));
    const subMenuLinkCount = header.subMenuLinks?.length ?? 0;

    return (
      <Base>
        {filteredFunctionLinks &&
          filteredFunctionLinks.map(
            (link, index) =>
              iconCodes.find(e => e === link.iconCode) && (
                <ActionButton
                  to={link.actionType === 'link' ? link.url : undefined}
                  key={index}
                  onClick={(e: any) => {
                    e.preventDefault();
                    MenuLinkItemOnClick(link, openAccountBox);
                  }}
                  Icon={
                    (link.iconCode === "FLAG" ? GetFlagIcon(props.twoLetterCountryCode) :
                      ((() => (
                        <ActionIconFromIconCode
                          iconCode={link.iconCode as iconCodes}
                        />
                      )) as unknown) as StyledComponent<any>)
                  }
                  withText={link.iconCode !== "FLAG" && !!link.title}
                  text={link.title}
                  title={link.title}
                />
              ),
          )
        }

        {wishListItem && wishListItem.url && !props.storeMode && (
          <CartIndicator inverseFGColor={inverseFGColor} onClick={null} linkItem={wishListItem} type={'wishlist'} />
        )}

        {cartLinkItem && cartLinkItem.url && !props.storeMode && (
          <CartIndicator inverseFGColor={inverseFGColor} onClick={null} linkItem={cartLinkItem} type={'cart'} />
        )}

        {!isMobile && subMenuLinkCount > 0 && <ActionButton onClick={toggleTopLinks} Icon={Menu24x24} withText />}
      </Base>
    );
  },
);

export default connect(
  state => ({
    pages: state.appShellData.pages,
    currentBreakpoint: state.currentBreakpoint,
    quickSearchIsOpen: state.quickSearch.isOpen,
    header: state.appShellData.header,
    twoLetterCountryCode: state.appShellData.context.twoLetterCountryCode,
    language: state.appShellData.market.language,
    market: state.appShellData.market,
    content: state.appShellData.content,
    storeMode: state.appShellData.context.isStoreMode,
  }),
  dispatch => ({
    openAccountBox(scene?: AccountBoxScene) {
      dispatch(openAccountBox(scene));
    },
  }),
)(ActionButtons);
