import React, { useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import { Style, StyleOrStyleArray } from '@glitz/type';
import connect from 'Shared/connect';
import { spring } from 'react-motion';
import MainMenuItemType from './MainMenuItem.type';
import { MainMenuType } from 'Shared/State';
import { setMainMenuCategory, toggleMainMenu } from 'SiteLayout/MainMenu/action-creators';
import Link from '../../Shared/Link';
import { translate } from '@polarnopyret/scope/index';
import SiteHeader from 'SiteLayout/Header/SiteHeader.type';
import { Right12x12, Right16x16, Right24x24, Left24x24 } from 'Shared/Icons/Arrow';
import MainMenuCategoryItem from 'SiteLayout/MainMenu/MainMenuCategoryItem.type';
import { pushState } from '@polarnopyret/scope';
import SecondaryMenuItem from 'SiteLayout/MainMenu/SecondaryMenuItem.type';
import { AccountBoxScene, openAccountBox } from 'SiteLayout/AccountBox/action-creators';
import { colorFromTag } from 'Shared/utils';
import Modal, { ModalScrollableContent } from 'Shared/SharedComponents/atoms/Modal';
import SwiperList from 'Shared/SwiperList';
import { calculateTopPadding, Ratio } from 'Shared/AspectRatio';
import { ActionIconFromIconCode, iconCodes, MenuLinkItemOnClick } from 'SiteLayout/MainMenu';
import MainMenuItem from './MainMenuItem.type';
import { CategoryModalContent, CategoryItemsModalContent } from './index';
import * as style from 'Shared/Style';
import GetFlagIcon from 'Shared/Icons/Flags'
import CartIndicatorButton from '../Header/CartIndicatorButton';

type RequiredPropType = {};

type ConnectedPropType = {
  mainMenu: MainMenuType;
  toggleMainMenu: (item?: MainMenuItemType) => void;
  setMainMenuCategory: (item: MainMenuItemType) => void;
  header: SiteHeader;
  twoLetterCountryCode: string;
  openAccountBox: () => void;
};

type PropType = RequiredPropType & ConnectedPropType;

const boldUcase: StyleOrStyleArray<Style> = {
  fontWeight: 500,
  textTransform: 'uppercase',
};

const ModalScrollableContentFirstTierMobile = styled(ModalScrollableContent, {
  paddingTop: '20px'
})

export const margin = 8;

const SecondaryMenuLabel = styled.span({
  fontSize: '16px',
  fontWeight: 500,
  textAlign: 'left',
  display: 'block',
  margin: { x: 0, bottom: '8px', top: '4px' },
})

const SecondaryMenuHeading = styled.span({
  ...boldUcase,
  fontSize: '40px',
  lineHeight: '100%',
  textAlign: 'left',
  display: 'block',
  margin: { x: 0, bottom: '16px', top: 0 },
})

const SecondaryMenuLink = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  textDecoration: 'none',
  color: style.colors.monochrome.black,
  borderBottom: {
    width: '2px',
    style: 'solid',
    color: style.colors.monochrome.black
  },
  paddingBottom: '12px',
  marginBottom: '12px',
  justifyContent: 'left',
})

const SecondaryMenuRightIcon = styled(Right12x12, {
  margin: { left: 4 }
})

const SecondaryMenuShowAllText = styled.span({
  ...boldUcase,
  fontSize: '14px',
  lineHeight: '100%',
  margin: { xy: 0 },
  display: 'inline-block',
})

const SecondaryMenuSwiperWrap = styled.div({
  width: '100%'
})

const SecondaryMenuItemLink = styled(Link, {
  paddingTop: calculateTopPadding(Ratio.NineToSixteen),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'flex-end',
  textDecoration: 'none',
})

const SecondaryMenuItemLinkText = styled.span({
  fontWeight: 500,
  textTransform: 'uppercase',
  fontSize: '20px',
  lineHeight: '100%',
  color: style.colors.monochrome.white,
  margin: { x: 'auto', bottom: '24px', top: 0 },
  textAlign: 'center',
  display: 'inline-block',
})

const GoBackWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'sticky',
  top: 0,
  marginBottom: '12px',
  paddingBottom: '12px',
  borderBottom: {
    width: '2px',
    style: 'solid',
    color: style.colors.monochrome.black
  },
})

const GoBackText = styled.span({
  textAlign: 'left',
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '100%',
  margin: { xy: 0, left: '16px' },
  textTransform: 'uppercase',
})

const onClickOrRedirect = ({
  onClick,
  item,
  e,
}: {
  onClick: () => void;
  item: MainMenuCategoryItem | SecondaryMenuItem;
  e?: React.MouseEvent;
}) => {
  if (e) e.preventDefault();
  return item.children?.length !== 0 ? onClick : () => pushState(item.url);
};

const GoBack = ({ action, bgColor }: { action: () => void, bgColor: string }) => (
  <GoBackWrap
    css={{
      backgroundColor: bgColor,
    }}
    onClick={action}
  >
    <Left24x24 />
    <GoBackText>
      {translate('/site/shared/back')}
    </GoBackText>
  </GoBackWrap>
);

const PrimaryLink = styled(Link, {
  ...boldUcase,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textDecoration: 'none',
  margin: { bottom: '20px' },
})

const PrimaryLinkText = styled.span({
  ...boldUcase,
  fontSize: '32px',
  textAlign: 'left',
  lineHeight: '100%',
  margin: { xy: 0 },
})

const PrimaryLinkGroup = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '40px'
})

const PrimaryLinkWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const PrimaryBaseLink = styled(Link, {
  color: style.colors.monochrome.black,
  textAlign: 'left',
  display: 'block',
  textDecoration: 'none',
})

const PrimarySecondaryLink = styled(PrimaryBaseLink, {
  fontWeight: 500,
  fontSize: '24px',
  margin: { xy: 0 },
  lineHeight: '120%',
})

const PrimarySubmenuLink = styled(PrimaryBaseLink, {
  ...boldUcase,
  fontSize: '16px',
  lineHeight: '100%',
  margin: { xy: 0 },
})

const PrimaryRelocatedLinksWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '12px',
  borderTop: {
    width: '2px',
    style: 'solid',
    color: style.colors.monochrome.black
  },
  marginBottom: '12px'
})

const PrimaryBottomLink = styled(Link, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textDecoration: 'none',
  color: style.colors.monochrome.black,
})

const PrimaryBottomLinkText = styled.span({
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '130%',
  textAlign: 'center',
  display: 'inline-block',
})

const PrimaryMenuModalContent = (
  {
    primaryMenuItems,
    header,
    twoLetterCountryCode,
    onCategoryClick,
    onSecondaryLinkClick,
    openAccountBox
  }: {
    primaryMenuItems: MainMenuItem[];
    header: SiteHeader;
    twoLetterCountryCode: string;
    onCategoryClick: (e: React.SyntheticEvent<{}>, item: MainMenuItemType) => void;
    onSecondaryLinkClick: (e: React.MouseEvent<Element, MouseEvent>, item: SecondaryMenuItem) => void;
    openAccountBox: () => void;
  }
) => {

  let functionMenuLinks = [...header.functionLinks];
  const wishListLink = header.functionLinks?.find(x => x.iconCode === 'WISHLIST');
  // if (wishListLink) {
  //   functionMenuLinks.unshift({ relocateForSmallDevice: true, iconCode: wishListLink.iconCode, title: wishListLink.title, actionType: wishListLink.actionType, url: wishListLink.url, targetNewWindow: false })
  // }

  const filteredSubMenuLinks = header.subMenuLinks && header.subMenuLinks.filter(f => f.relocateForSmallDevice === true);
  if (filteredSubMenuLinks) {
    functionMenuLinks = functionMenuLinks.concat(filteredSubMenuLinks);
  }

  return (
    <>
      {primaryMenuItems.map((item) => (
        <PrimaryLink
          to={item.url}
          title={item.name}
          key={item.name}
          css={{
            color: colorFromTag(item.tagName) ?? style.colors.monochrome.black,
          }}
          onClick={(e) => {
            if (item.children?.length > 0) onCategoryClick(e, item);
          }}
        >
          <PrimaryLinkText>{item.name}</PrimaryLinkText>
          {item.children && item.children.length > 0 && <Right24x24 />}
        </PrimaryLink>
      ))}
      <PrimaryLinkGroup>
        {header.secondaryMenuItems.map((item, i) => (
          <PrimaryLinkWrap key={i}>
            <PrimarySecondaryLink
              to={item.url}
              title={item.name}
              onClick={() => onSecondaryLinkClick}
            >
              {item.name}
            </PrimarySecondaryLink>
            {item.children.length > 0 && <Right16x16 />}
          </PrimaryLinkWrap>
        ))}
      </PrimaryLinkGroup>
      <PrimaryLinkGroup>
        {header.subMenuLinks && header.subMenuLinks
          .filter((link) => !link.relocateForSmallDevice)
          .map((item, i, a) => (
            <PrimaryLinkWrap key={i}>
              <PrimarySubmenuLink
                to={item.url}
                title={item.title}
              >
                {item.title}
              </PrimarySubmenuLink>
            </PrimaryLinkWrap>
          ))}
      </PrimaryLinkGroup>

      <PrimaryRelocatedLinksWrap className={'subMenuLinks-relocated'}>

        {wishListLink && wishListLink.url && (
          <CartIndicatorButton
            url={wishListLink.url}
            title={wishListLink.title}
            cartType={'wishlist'}
            withText={true}
            alwaysText={true}
            onClick={null}
            inverseFGColor={false}
          />
        )}

        {functionMenuLinks && functionMenuLinks
          .filter((link) => link.relocateForSmallDevice)
          .map((item, i) => (
            <PrimaryBottomLink
              to={item.url}
              title={item.title}
              key={i}
              onClick={(e) => {
                e.preventDefault();
                if (item.iconCode === 'CART') return undefined;
                return MenuLinkItemOnClick(item, openAccountBox);
              }}
            >
              {iconCodes.find((e) => e === item.iconCode) && (
                item.iconCode === "FLAG" ? GetFlagIcon(twoLetterCountryCode) : <ActionIconFromIconCode iconCode={item.iconCode as iconCodes} />
              )}
              <PrimaryBottomLinkText
                css={{
                  marginTop: iconCodes.find((e) => e === item.iconCode) ? 4 : 0,
                }}
              >
                {item.title}
              </PrimaryBottomLinkText>
            </PrimaryBottomLink>
          ))}
      </PrimaryRelocatedLinksWrap>
    </>
  );
};

const MobileMenu = (props: PropType) => {
  const onCategoryClick = (e: React.SyntheticEvent<{}>, item: MainMenuItemType) => {
    e.preventDefault();
    props.setMainMenuCategory(item);
  };

  const { mainMenu } = props;
  const isOpen = mainMenu.selectedCategory && props.mainMenu.isOpen;
  const [secondaryMenu, setSecondaryMenu]: [SecondaryMenuItem, any] = useState(undefined);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [activeMenuItem, setSelectedMenuItem] = useState('');
  const thirdTier = mainMenu.selectedCategory?.children[selectedTabIndex]?.children.find(
    (child, i) => activeMenuItem === i + '-' + child.name,
  );

  useEffect(() => {
    setSelectedTabIndex(0);
  }, [mainMenu.selectedCategory]);

  useEffect(() => {
    setSelectedMenuItem('');
    setSecondaryMenu(undefined);
  }, [selectedTabIndex, mainMenu.selectedCategory, props.mainMenu.isOpen]);

  const motionStyle = {
    opacity: spring(mainMenu.isOpen ? 1 : 0),
  };

  const onSecondaryLinkClick = (e: React.MouseEvent<Element, MouseEvent>, item: SecondaryMenuItem) => {
    e.preventDefault();
    onClickOrRedirect({
      onClick: () => {
        setSecondaryMenu(item);
      },
      item,
      e,
    })()
  };

  const primaryMenuModal = PrimaryMenuModalContent({ primaryMenuItems: props.mainMenu.primaryMenuItems, header: props.header, twoLetterCountryCode: props.twoLetterCountryCode, onCategoryClick, onSecondaryLinkClick: onSecondaryLinkClick, openAccountBox });
  const secondaryMenuModal = CategoryModalContent({ isOpen: mainMenu.selectedCategory !== null, selectedTabIndex, selectedTopItem: mainMenu.selectedCategory, setSelectedTabIndex, activeMenuItem, setSelectedMenuItem })
  const thirdMenuModal = CategoryItemsModalContent({ selectedItem: thirdTier, isMobile: true });

  const secondTierChildren = (
    <>
      {mainMenu.selectedCategory && (
        <>
          <GoBack
            action={() => {
              props.setMainMenuCategory(undefined);
              props.toggleMainMenu();
            }}
            bgColor={style.colors.monochrome.white}
          />
          {secondaryMenuModal}
        </>
      )}
    </>
  );

  const thirdTierChildren = (
    <>
      {thirdTier && (
        <>
          <GoBack action={() => setSelectedMenuItem('')} bgColor={style.colors.monochrome.extraLightGrey} />
          {thirdMenuModal}
        </>
      )}
    </>
  );

  return (
    <Modal modalIsShown={props.mainMenu.isOpen} hideModal={() => props.toggleMainMenu()} isOnLeft
      backgroundColor={activeMenuItem !== '' ? style.colors.monochrome.extraLightGrey : style.colors.monochrome.white}
    >
      {props.mainMenu.isOpen && !isOpen && secondaryMenu === undefined && (
        <ModalScrollableContentFirstTierMobile>{primaryMenuModal}</ModalScrollableContentFirstTierMobile>
      )}
      {isOpen && activeMenuItem === '' && secondaryMenu === undefined && (
        <ModalScrollableContent>{mainMenu.selectedCategory && secondTierChildren}</ModalScrollableContent>
      )}
      {activeMenuItem !== '' && (
        <ModalScrollableContent>{thirdTier && thirdTierChildren}</ModalScrollableContent>
      )}
      {secondaryMenu !== undefined && (
        <ModalScrollableContent>
          {secondaryMenu && (
            <>
              <GoBack action={() => setSecondaryMenu(undefined)} bgColor={style.colors.monochrome.white} />
              {secondaryMenu.label && (
                <SecondaryMenuLabel>
                  {secondaryMenu.label}
                </SecondaryMenuLabel>
              )}
              <SecondaryMenuHeading>
                {secondaryMenu.name}
              </SecondaryMenuHeading>
              {secondaryMenu.showAllText && (
                <SecondaryMenuLink
                  to={secondaryMenu.url}
                  title={secondaryMenu.showAllText}
                  onClick={(e) => {
                    e.preventDefault();
                    pushState(secondaryMenu.url);
                  }}
                >
                  <SecondaryMenuShowAllText>{secondaryMenu.showAllText}</SecondaryMenuShowAllText>
                  <SecondaryMenuRightIcon />
                </SecondaryMenuLink>
              )}
              <SecondaryMenuSwiperWrap>
                <SwiperList
                  isCompact={true}
                  spaceBetween={8}
                  slidesPerView={1.2}
                  cssMode={true}
                >
                  {secondaryMenu.children.map((child, i, a) => (
                    <div key={i} className="swiper-slide">
                      <SecondaryMenuItemLink
                        css={{
                          backgroundImage: `url(${child.imageUrl})`,
                        }}
                        to={child.url}
                        title={child.name}
                        onClick={(e) => {
                          e.preventDefault();
                          pushState(child.url);
                        }}
                      >
                        <SecondaryMenuItemLinkText>{child.name}</SecondaryMenuItemLinkText>
                      </SecondaryMenuItemLink>
                    </div>
                  ))}
                </SwiperList>
              </SecondaryMenuSwiperWrap>
            </>
          )}
        </ModalScrollableContent>
      )}
    </Modal>
  );
};

export const MobileTieredModal = connect(
  (state) => ({
    mainMenu: state.mainMenu,
    header: state.appShellData.header,
    twoLetterCountryCode: state.appShellData.context.twoLetterCountryCode,
  }),
  (dispatch) => ({
    toggleMainMenu: (item: MainMenuItemType) => {
      dispatch(toggleMainMenu(item));
    },
    setMainMenuCategory(item: MainMenuItemType) {
      dispatch(setMainMenuCategory(item));
    },
    openAccountBox(scene?: AccountBoxScene) {
      dispatch(openAccountBox(scene));
    },
  }),
)(MobileMenu);