import * as React from 'react';
import { translate } from '@polarnopyret/scope';
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import { CartFull24x24, Cart24x24 } from 'Shared/Icons/Cart';
import { Favourite24x24, FavouriteFull24x24 } from 'Shared/Icons/Favorite';
import { ActionButton } from './ActionButtons';
import * as style from 'Shared/Style';
import { Indicator } from './Indicator';
import Link from 'Shared/Link';
import { ANIMATION_DURATION } from 'SiteLayout/Header/DesktopHeader';
import { ShowCartModal } from 'Shared/uiReducer';
import { AccountBoxScene, openAccountBox } from 'SiteLayout/AccountBox/action-creators';

type ConnectedProps = {
  cartCount: number;
  wishlistCount: number;
  loggedIn: boolean;
  showCartModal: () => void;
  openAccountBox: () => void;
};

type PropType = ConnectedProps & {
  withText?: boolean;
  alwaysText?: boolean;
  lowIndicator?: boolean;
  cartType: string;
  url: string;
  title: string;
  onClick: (e: any) => void;
  inverseFGColor: boolean;
};

const Base = styled.div({
  position: 'relative',
  ...style.transition({ property: ['transform, background-color'], duration: `${ANIMATION_DURATION}ms` }),
});

const CartLinkBase = styled(Link, {
  display: 'block',
  position: 'absolute',
  top: '5.4px',
  left: 'auto',
  right: '3.7px',
  textDecoration: 'none',
  color: 'inherit',
});

const CartLink = styled(CartLinkBase, {
  ['@media ' + style.mediaMinLarge]: {
    top: '6.1px',
    left: 'auto',
    right: '2px',
  },
});

const CartButton = styled((props: PropType & StyledProps) => {
  // These has to be 3 separate calls to translate as the
  // translation-loader won't pick them up if you use a dynamic string.
  const isCart = props.cartType.toLowerCase() === 'cart';
  const isLoggedIn = props.loggedIn;
  const cartCount = isCart ? props.cartCount : props.wishlistCount;
  const title = isCart ?
    (cartCount === 0
      ? translate('/site/cart/empty')
      : (cartCount === 1
        ? translate('/site/cart/single')
        : translate('/site/cart/multiple', cartCount))) : props.title;
  const CartLinkType = CartLink;

  const onClick =
    isCart ? (
      !isLoggedIn ? props.onClick :
        (e: React.MouseEvent<HTMLElement>) => {
          const cartCount = isCart ? props.cartCount : props.wishlistCount;
          if (cartCount === 0) {
            e.preventDefault();
            return false;
          }
          e.preventDefault();
          props.showCartModal();
        }
    ) : (
      isLoggedIn ? props.onClick :
        (e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          props.openAccountBox();
        }
    );

  const icon = isCart ? (cartCount === 0 ? Cart24x24 : CartFull24x24) : (cartCount === 0 ? Favourite24x24 : FavouriteFull24x24);
  const iconTextColor = props.inverseFGColor ? style.colors.monochrome.black : style.colors.monochrome.white;

  return (
    <Base css={{ fill: 'transparent' }}>
      <ActionButton
        title={title}
        to={props.url}
        Icon={icon}
        withText={props.withText}
        alwaysText={props.alwaysText}
        text={props.title}
        onClick={onClick}
      />
      {isCart &&
        <CartLinkType title={title} to={props.url} onClick={onClick}>
          <Indicator css={{ color: iconTextColor }}>{cartCount}</Indicator>
        </CartLinkType>
      }
    </Base>
  );
});

export default connect(
  state => ({
    cartCount: state.cart?.items?.reduce((prev: number, item: { quantity: number }) => prev + item.quantity, 0),
    wishlistCount: state.wishlist?.items?.length,
    loggedIn: state.currentUser?.isLoggedIn ?? false,
  }),
  dispatch => ({
    showCartModal: () => dispatch(ShowCartModal()),
    openAccountBox(scene?: AccountBoxScene) {
      dispatch(openAccountBox(scene));
    },
  }),
)(CartButton);
